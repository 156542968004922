import { CollectConfiguration } from './Collect';
import { ExternalReferenceIds } from './ExternalReferenceId';
import { Locale, Day, LocalizedValue } from './Localisation';
import { ImageWithSize } from './Media';
import { OpeningHoursInterval } from './OpeningHours';
import { Pagination } from './Pagination';
import { SelectedProductDetails } from './ProductSelection';
import { SlideshowSlide } from './Slideshow';
import { Sorting } from './Sorting';
export const DEVICE_SCREEN_RESOLUTIONS = ['1080x1920', '1920x1080', '1280x1024', '2112x1048'] as const;
export type DeviceScreenResolution = (typeof DEVICE_SCREEN_RESOLUTIONS)[number];

export interface DeviceCapabilityFlags {
    robotStockIsAvailable: boolean;
    robotDeliveryIsAvailable: boolean;
    barcodeScannerIsAvailable: boolean;
    payNLIsAvailable: boolean;
    videoPhoneIsAvailable: boolean;
    connectedToLockers?: boolean;
}

export interface DeviceCapabilityConfiguration {
    robotUrl?: string | null;
    robotStockLocations?: string[] | null;
    robotOutputDestination?: number | null;
    transportTimeFromRobot?: number | null;
    transportTimeForBoxSensor?: number | null;
    deliveryCameraIpAddress?: string | null;
    videoPhoneIpAddress?: string | null;
    videoPhoneAnswerAsSIPExtension?: string | null;
    videoPhoneAnswerAsSIPSecret?: string | null;
    videoPhoneCallAsSIPExtension?: string | null;
    videoPhoneCallAsSIPSecret?: string | null;
    robotZone?: string | null;
}

export interface RequestRobotProductDeliveryPayload {
    amountsByProductCode: {
        [key: string]: number;
    };
    robotOutputDestinationId: number;
}
export interface RequestPickupProductsDeliveryPayload {
    pickupIds: string[];
    robotOutputDestinationId: number;
}

export type VendingMachineViewMode = 'standard' | 'wheelchair';
export type VendingMachineDayNightMode = 'day' | 'night';

export type Device = {
    deviceId: string;
    name: string;
    label?: string | null;
    type: 'tv-screen' | 'interactive-tv-screen' | 'vending-machine';
    groupName: string;
    screenResolution: DeviceScreenResolution;
    companyId: string;
    branchId: string;
    companyName: string;
    branchName: string;
    isActive: boolean;
    disableMonitoringMessages: boolean;
    macAddress: string | null;
    teamViewerId?: string | null;
    comments?: string | null;
    externalReferenceIds?: ExternalReferenceIds | null;
    standbyMode?: DeviceStandbyMode | null;
    isGoodieBagEnabled?: boolean;
    goodieBagProductId?: string | null;
    goodieBagProductDetails?: SelectedProductDetails | null;
    useSingleSharedGoodieBagPickupCode?: boolean;
    sharedGoodieBagPickupCode?: string | null;
    enableIdentifiedCustomers?: boolean;
    pharmacyOnDutyPhoneNumber?: string | null;
} & DeviceCapabilityFlags;

export interface DeviceHiddenSlide {
    slideId: string;
    type: 'productWall' | 'customPage' | 'video' | 'image';
    itemId: string;
    name: string;
}

export type DeviceHiddenSlides = DeviceHiddenSlide[];

export interface DeviceImages {
    logo?: ImageWithSize;
}

export interface DeviceHomeScreenCategory {
    categoryId: string;
    isSelected: boolean;
    sequence: number;
    productIds: string[];
    customCategoryName?: LocalizedValue;
    customCategoryMediaItemId?: string;
}

export type DeviceHomeScreenProductsV1 = DeviceHomeScreenCategory[];

export interface DeviceHomeScreenProductsV2 {
    categories: DeviceHomeScreenCategory[];
    homeScreenIsCategoriesGrid: boolean;
}

export type DeviceHomeScreenProducts = DeviceHomeScreenProductsV1 | DeviceHomeScreenProductsV2;

export function isDeviceHomeScreenProductsV1(config: DeviceHomeScreenProducts): config is DeviceHomeScreenProductsV1 {
    return !config.hasOwnProperty('homeScreenIsCategoriesGrid');
}

export function ensureDeviceHomeScreenProductsV2(config?: DeviceHomeScreenProducts): DeviceHomeScreenProductsV2 {
    let configV2: DeviceHomeScreenProductsV2 = {
        categories: [],
        homeScreenIsCategoriesGrid: false,
    };

    if (config && isDeviceHomeScreenProductsV1(config)) {
        configV2.categories = config;
    } else if (config) {
        configV2 = config;
    }

    return configV2;
}

export interface DeviceHomeScreenProductsConfiguration {
    homeScreenProducts?: DeviceHomeScreenProducts;
}

export type VendingMachineSlideshow = SlideshowSlide[];

export type PharmacistOnDutyScheduleInterval = OpeningHoursInterval & {
    phoneNumber: string;
    displayOnDutyPhoneNumber?: boolean;
    allowVideoCalls?: boolean;
};

export interface PharmacistOnDutySchedule {
    weekdays: {
        [p in Day]?: PharmacistOnDutyScheduleInterval[];
    };
    exceptions: PharmacistOnDutyScheduleInterval[];
}

export const DEVICE_STANDBY_MODES = [
    'off',
    'disable-everything',
    'disable-payments',
    'disable-pickups',
    'disable-robot',
    'disable-lockers',
] as const;
export type DeviceStandbyMode = (typeof DEVICE_STANDBY_MODES)[number];

export interface DeviceGeneralOptions {
    simulateStock?: boolean;
    simulateProductDelivery?: boolean;
    simulatePayments?: boolean;
    simulateLockers?: boolean;
    simulateBarcodeScanner?: boolean;
    forcedViewMode?: VendingMachineViewMode | null;
    forcedDayNightMode?: VendingMachineDayNightMode | null;
    mtMaticPayConfiguredIntegrationId?: string | null;
    standbyMode?: DeviceStandbyMode | null;
    standbyMessage?: string | null;
}
export type DeviceOpeningHoursScheduledSlideshow =
    | {
          openingHoursScheduledSlideshowId?: null;
          openingHoursScheduledSlideshowName?: null;
          openingHoursScheduledTimelineName?: null;
          openingHoursScheduledTimelineId?: null;
      }
    | {
          openingHoursScheduledSlideshowId: string;
          openingHoursScheduledSlideshowName: string;
          openingHoursScheduledTimelineName: string;
          openingHoursScheduledTimelineId: string;
      };

export const DevicePaymentMethods = ['visa', 'mastercard', 'maestro', 'payconiq', 'bancontact', 'carte_bleue'] as const;
export type DevicePaymentMethod = (typeof DevicePaymentMethods)[number];

export interface DevicePrintLabelConfiguration {
    paperWidth: number;
    paperHeight: number;
    codeWidth: number;
    codeHeight: number;
    codePositionLeft: number;
    codePositionTop: number;
}

export type DeviceDetails = Device &
    DeviceCapabilityConfiguration &
    DeviceOpeningHoursScheduledSlideshow & {
        contentVersion?: number | null;
        updatedOn?: string | null;
        branchIndex: number;

        companyLocales: Locale[];
        screenshotUrl?: string | null;

        hiddenSlides: DeviceHiddenSlides | null;

        configuration: {
            fontSet?: string;
            colorSet?: string;
            general?: DeviceGeneralOptions;
            lockerGroupConfiguration?: CollectConfiguration;
            nextpharmWebSocket?: string | null;
            mtMaticPayConfiguredIntegrationId?: string | null;
            mtCollect?: {
                stockScreenCode: string;
                managementScreenCode: string;
            } | null;
            printLabel?: DevicePrintLabelConfiguration | null;
            enabledComponents?: UpdateDeviceEnabledComponentsDetails;
            enabledComponentsWhenOnDuty?: UpdateDeviceEnabledComponentsDetails;
            enableIdentifiedCustomers?: boolean;
        } & DeviceImages &
            DeviceHomeScreenProductsConfiguration;

        pharmacistOnDutySchedule?: PharmacistOnDutySchedule | null;
        notes?: string | null;

        touchTheScreenCallToActionPosition?: TouchTheScreenCallToActionPosition | null;
    };

export interface DevicesPage {
    items: Device[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof Device> | null;
    search?: string | null;
}

export type CreateDevicePayload = Omit<Device, 'companyName' | 'branchName' | 'companyId' | 'payNLIsAvailable'> &
    DeviceCapabilityConfiguration &
    DeviceGeneralOptions & {
        nextPharmWebSocket?: string | null;
    };

export type UpdateDevicePayload = Omit<Device, 'companyName' | 'branchName' | 'companyId' | 'branchId' | 'payNLIsAvailable'> &
    DeviceCapabilityConfiguration &
    DeviceGeneralOptions & {
        notes?: string | null;
        nextPharmSocket?: string | null;
        touchTheScreenCallToActionPosition: TouchTheScreenCallToActionPosition;
    };

export interface UpdateDeviceHiddenSlidesPayload {
    deviceId: string;
    hiddenSlides: DeviceHiddenSlides | null;
}

export interface UpdateDeviceConfigurationPayload {
    deviceId: string;
    fontSetId: string;
    colorSetId: string;
}

export type UpdateDeviceImageWithSizePayload = {
    deviceId: string;
    base64encodeImageContents?: string;
} & Omit<ImageWithSize, 'key'>;

export interface UpdateDeviceInPaymentTestingModePayload {
    deviceId: string;
    isInPaymentTestingMode: boolean;
}

export interface UpdateDeviceHomeScreenProductsPayload {
    deviceId: string;
    homeScreenProducts: DeviceHomeScreenProductsV2;
}

export interface UpdateDeviceLabelPayload {
    deviceId: string;
    label: string;
}

export interface UpdateDeviceNotesPayload {
    deviceId: string;
    notes: string;
}

export interface UpdateVendingMachinePharmacistOnDutySchedulePayload {
    deviceId: string;
    pharmacistOnDutySchedule: PharmacistOnDutySchedule;
}

export interface UpdateDeviceOpeningHoursScheduledTimelineIdPayload {
    deviceId: string;
    timelineId?: string | null;
}

export interface UpdateDeviceStandbyModePayload {
    deviceIds: string;
    standbyMode: DeviceStandbyMode;
    standbyMessage: string;
}

export interface UpdateDeviceMtCollectManagementCodesPayload {
    deviceId: string;
    codeForStockScreen: string;
    codeForManagementScreen: string;
}

export type UpdateDevicePrintLabelConfigurationPayload = {
    deviceId: string;
} & DevicePrintLabelConfiguration;

export interface CreateRobotEmergencyStopNotificationPayload {
    deviceId: string;
}

export const UpdateDeviceOnDutyEnabledComponents = [
    'catalogueIsEnabled',
    'callPharmacistIsEnabled',
    'pharmacyOnDutyIsEnabled',
    'reservationsAreEnabled',
] as const;

export type UpdateDeviceOnDutyEnabledComponents = (typeof UpdateDeviceOnDutyEnabledComponents)[number];

export type UpdateDeviceEnabledComponentsDetails = {
    [key in UpdateDeviceOnDutyEnabledComponents]?: boolean;
};

export type DeviceEnabledComponentsType = 'onDuty' | 'offDuty';

export type UpdateDeviceEnabledComponentsPayload = {
    deviceId: string;
    deviceEnabledComponentsType?: DeviceEnabledComponentsType;
} & UpdateDeviceEnabledComponentsDetails;

export const TouchTheScreenCallToActionPositions = ['off', 'topCenter', 'topLeft', 'bottomRight'] as const;
export type TouchTheScreenCallToActionPosition = (typeof TouchTheScreenCallToActionPositions)[number];

export interface UpdateDeviceTouchTheScreenCallToActionPositionPayload {
    deviceId: string;
    position: TouchTheScreenCallToActionPosition;
}

export interface UpdatePharmacyOnDutyPhoneNumberPayload {
    deviceId: string;
    pharmacyOnDutyPhoneNumber?: string;
}
