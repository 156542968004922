import { Locale, LocalizedValue } from './Localisation';
import { Pagination } from './Pagination';
import { Sorting } from './Sorting';

export interface CmsTag {
    cmsTagId: string;
    name: string;
    locale: Locale;
    color: string;
    createdOn: string;
    updatedOn: string;
}

export type CreateCmsTagPayload = Omit<CmsTag, 'createdOn' | 'updatedOn'>;

export type UpdateCmsTagPayload = Omit<CreateCmsTagPayload, 'locale'>;

export interface RemoveCmsTagPayload {
    cmsTagId: string;
}

export interface CmsTagsPage {
    items: CmsTag[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof CmsTag> | null;
    search?: string | null;
}

export type CmsItemStatus = 'active' | 'inactive';

export interface CmsItemBlockMediaItem {
    mediaItemId: string;
    url: string;
}

export interface CmsItemTableCell {
    content: string;
}

export type CmsItemTableRow = CmsItemTableCell[];
export interface CmsItemBlockTable {
    numRows: number;
    numCols: number;
    rows: CmsItemTableRow[];
}

export type LocalizedCmsItemBlock =
    | {
          blockId: string;
          type: 'html';
          content: LocalizedValue<string>;
      }
    | {
          blockId: string;
          type: 'image';
          content?: CmsItemBlockMediaItem;
      }
    | {
          blockId: string;
          type: 'table';
          content: LocalizedValue<CmsItemBlockTable>;
      };

export type CmsItemBlock =
    | {
          blockId: string;
          type: 'html';
          content: string;
      }
    | {
          blockId: string;
          type: 'image';
          content?: CmsItemBlockMediaItem;
      }
    | {
          blockId: string;
          type: 'table';
          content: CmsItemBlockTable;
      };

export const CmsItemDutyComponentTypes = [
    'vendingMachineCatalogueIsEnabledInfo',
    'vendingMachineCallPharmacistIsEnabledInfo',
    'vendingMachinePharmacyOnDutyIsEnabledInfo',
    'vendingMachineReservationsAreEnabledInfo',
    'vendingMachineCatalogueIsEnabledWhenOffDutyInfo',
    'vendingMachineCallPharmacistIsEnabledWhenOffDutyInfo',
    'vendingMachinePharmacyOnDutyIsEnabledWhenOffDutyInfo',
    'vendingMachineReservationsAreEnabledWhenOffDutyInfo',
];

export const CmsItemTypes = [
    'privacyPolicyPage',
    'termsAndConditionsPage',
    'visionPromoMessage',
    'vendingMachinePromoMessage',
    'webshopPromoMessage',
    'vendingMachinePrivacyPolicy',
    ...CmsItemDutyComponentTypes,
] as const;

export type CmsItem = {
    cmsItemId: string;
    name: LocalizedValue;
    locales: Locale[];
    status: CmsItemStatus;
    tagIds: LocalizedValue<string[]>;
    tags: CmsTag[];
    createdOn: string;
    updatedOn?: string | null;
    activeFrom?: string | null;
    activeUntil?: string | null;
    pinnedFrom?: string | null;
    pinnedUntil?: string | null;
} & (
    | {
          type: 'announcement';
          configuration: {
              popup?: boolean | null;
          };
          blocks: LocalizedCmsItemBlock[];
      }
    | {
          type: (typeof CmsItemTypes)[number];
          configuration: Record<PropertyKey, never>;
          blocks: CmsItemBlock[];
      }
);

export type CmsItemType = CmsItem['type'];

export type CreateCmsItemPayload = Omit<CmsItem, 'createdOn' | 'updatedOn' | 'tags'>;

export type UpdateCmsItemPayload = Omit<CreateCmsItemPayload, 'type'>;

export interface RemoveCmsItemPayload {
    cmsItemId: string;
}

export interface CmsItemsPage {
    items: CmsItem[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof CmsItem> | null;
    search?: string | null;
}

export type PublicAnnouncementsPage = CmsItemsPage;
export type PublicCmsTagsPage = CmsTagsPage;
