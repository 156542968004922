import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { cmsApi } from '@/api';
import { selectCurrentLocale } from '@/I18n/selectors';
import { RootState } from '@/store';

export interface State {
    items: {
        [key in `${Domain.CmsItemType}-${Domain.Locale}`]?: Domain.CmsItem;
    };
}
const initialState: State = {
    items: {},
};

const reducerActions = {
    setItem: createAction(
        '@cmsItem/infoItems/setItem',
        withPayloadType<{
            type: Domain.CmsItemType;
            locale: Domain.Locale;
            cmsItem: Domain.CmsItem | undefined;
        }>(),
    ),
};

export const infoItemsReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setItem, (state, action) => {
        state.items[`${action.payload.type}-${action.payload.locale}`] = action.payload.cmsItem;
    }),
);

export const maybeSelectCmsItem: (type: Domain.CmsItemType, locale: Domain.Locale) => Selector<RootState, Domain.CmsItem | undefined> =
    (type, locale) => state =>
        state.cmsItem.infoItems.items[`${type}-${locale}`];

async function getCmsItem(type: Domain.CmsItemType, locale: Domain.Locale) {
    try {
        return await cmsApi.GetCmsItemDetailsByTypeAndLocale(type, locale);
    } catch (e) {
        console.error(e);
        return undefined;
    }
}

export const preloadCMSItems =
    (types: Domain.CmsItemType[]): ThunkAction<Promise<void>> =>
    async (dispatch, getState) => {
        const state = getState();
        const locale = selectCurrentLocale(state);
        const cmsItems = await Promise.all(types.map(type => getCmsItem(type, locale)));

        for (const cmsItem of cmsItems) {
            if (!cmsItem) {
                return;
            }

            for (const locale of cmsItem.locales) {
                dispatch(reducerActions.setItem({ type: cmsItem.type, locale, cmsItem }));
            }
        }
    };
