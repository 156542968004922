import { Branch } from './Branch';
import { Company } from './Company';
import { IntegrationDetails } from './Integration';
import { Locale, Country, LocalizedValue } from './Localisation';
import { ImageWithSize } from './Media';
import { OpeningHoursInterval } from './OpeningHours';
import { Pagination } from './Pagination';
import { Sorting } from './Sorting';
import { CartRestriction } from './WebshopCartRestriction';
import { WebshopLayout } from './WebshopLayout';
import {
    WebshopPickupShippingMethod,
    WebshopLockerShippingMethod,
    WebshopLocalDeliveryShippingMethod,
    WebshopServiceDeliveryShippingMethod,
} from './WebshopShippingMethod';

export interface BaseWebshopProps {
    webshopId: string;
    name: string;
}

export interface MagentoCredentialProps {
    magentoApiHost: string;
    magentoApiKey: string;
}

export interface WebshopOwnershipProps {
    companyId: Company['companyId'];
    branchId: Branch['branchId'];
}

export type Webshop = BaseWebshopProps &
    WebshopOwnershipProps &
    MagentoCredentialProps & {
        companyName: Company['name'];
        branchName: Branch['branchName'];
        lastOrdersImportedAt?: string | null;
        locales: Locale[];
        apiConnectionStatus: 'unknown' | 'ok' | 'failed';
        webshopHealthStatus: 'unknown' | 'ok' | 'failed' | 'down';
        webshopLiveStatus?: 'test' | 'live';
        metaData?: any | null;
        defaultLocale?: Locale | null;
        configuredIntegrationDetails?: IntegrationDetails[];
        isOnVacation?: boolean;
    };

export type WebshopDetails = Webshop & {
    defaultLocale: Locale;
    analyticsEmbedUrl?: string | null;
};

export interface WebshopsPage {
    items: Webshop[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof Webshop> | null;
    search?: string | null;
}

export type UnassignedWebshop = Omit<Webshop, 'companyId' | 'branchId' | 'companyName' | 'branchName'> & {
    companyId: null;
    branchId: null;
    companyName: null;
    branchName: null;
};

export type UnassignedWebshopDetails = UnassignedWebshop & {
    defaultLocale: Locale;
};

export interface UnassignedWebshopsPage {
    items: UnassignedWebshop[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof UnassignedWebshop> | null;
    search?: string | null;
}

export interface UpdateWebshopPayload {
    webshopId: string;
    name: string;
    defaultLocale: Locale;
    locales: Locale[];
}

export interface AssignWebshopToBranchPayload {
    webshopId: string;
    branchId: Branch['branchId'];
    locales: Locale[];
    defaultLocale: Locale;
}

export interface WebshopImages {
    favicon?: string;
    webshopLogo?: ImageWithSize;
    webshopLogoDark?: ImageWithSize;
    emailLogo?: ImageWithSize;
}

export const SocialMediaPlatforms = ['facebook', 'linkedin', 'twitter', 'instagram', 'youtube', 'pinterest'] as const;
export type SocialMediaPlatform = (typeof SocialMediaPlatforms)[number];

export interface SocialMediaLink {
    platform: SocialMediaPlatform;
    url: string;
}

export interface WebshopSocialMedia {
    socialMediaLinks?: SocialMediaLink[];
}

export interface WebsbopPaymentMethod {
    type: string;
    allowed_countries: Country[];
}

export interface WebshopPaymentConfiguration {
    methods: WebsbopPaymentMethod[];
    webshopPayConfiguredIntegrationId?: string | null;
}

export interface WebshopSocialMedia {
    socialMediaLinks?: SocialMediaLink[];
}

export interface WebshopVacationConfiguration {
    dateRanges: OpeningHoursInterval[];
    description: LocalizedValue;
}

export interface WebshopVacation {
    vacationConfiguration?: WebshopVacationConfiguration;
}

export interface WebshopVacationAnnouncement {
    vacationAnnouncementConfiguration?: WebshopVacationConfiguration;
}

export interface WebshopShippingMethods {
    shippingMethods?: {
        pickup?: WebshopPickupShippingMethod;
        locker?: WebshopLockerShippingMethod;
        localDelivery?: WebshopLocalDeliveryShippingMethod;
        serviceDelivery?: WebshopServiceDeliveryShippingMethod;
    };
}

export type WebshopMailServer = 'localhost' | 'lochtingMailServer';

export interface WebshopTranslateOption {
    translateOption?: {
        activeStatus?: 'active' | 'inactive';
    };
}

export interface WebshopExtraProcessingTimes {
    extraProcessingTimes?: {
        local: number;
        wholesale: number;
    };
}

export type WebshopConfiguration = {
    general: {
        title?: string | null;
        description?: string | null;
        name?: string | null;
        titular?: string | null;
        licenseNumber?: string | null;
        phoneNumber?: string | null;
        country?: Country | null;
        region?: string | null;
        postcode?: string | null;
        city?: string | null;
        streetName?: string | null;
        streetNumber?: string | null;
        vatNumber?: string | null;
        generalContactEmail?: string | null;
        generalContactName?: string | null;
        salesContactEmail?: string | null;
        salesContactName?: string | null;
        coordinates?: {
            latitude: number;
            longitude: number;
        } | null;
        welcomeMessage?: string | null;
        copyrightMessage?: string | null;
        localizedPharmacyOnDutyUrl?: LocalizedValue | [];
        disableNewsletterSubscriptions?: boolean;
        productsStockSubscriptionsEnabled?: boolean;
        autoCreatePickupsForOrders?: boolean;
        autoCreatePickupsForUnpaidOrders?: boolean;
        outOfStockThreshold?: number | null;
    };
    layout?: WebshopLayout;
    payment?: WebshopPaymentConfiguration;
    mailServer?: WebshopMailServer;
    ProductCategoriesSequence?: string[];
    shoppingCartRestrictions?: CartRestriction[];
    activeTranslationLanguages?: string[] | null;
} & WebshopImages &
    WebshopSocialMedia &
    WebshopVacation &
    WebshopVacationAnnouncement &
    WebshopShippingMethods &
    WebshopTranslateOption &
    WebshopExtraProcessingTimes;

export type UpdateWebshopConfigurationPayload = WebshopConfiguration['general'] & {
    webshopId: string;
    activeTranslationLanguages?: string[];
};

export type UpdateWebshopPaymentConfigurationPayload = {
    webshopId: string;
} & WebshopPaymentConfiguration;

export interface UpdateWebshopSocialMediaLinksPayload {
    socialMediaLinks: WebshopConfiguration['socialMediaLinks'];
    webshopId: string;
}

export interface UpdateWebshopImagePayload {
    webshopId: string;
    base64encodeImageContents: string;
}

export type UpdateWebshopImageWithSizePayload = {
    webshopId: string;
    base64encodeImageContents?: string;
} & Omit<ImageWithSize, 'key'>;

export type UpdateWebshopVacationConfigurationPayload = {
    webshopId: string;
} & WebshopVacationConfiguration;

export type UpdateWebshopVacationAnnouncementConfigurationPayload = UpdateWebshopVacationConfigurationPayload;

export interface UpdateWebshopMailServerPayload {
    webshopId: string;
    mailServer: WebshopConfiguration['mailServer'];
}

export interface UpdateTranslateOptionActiveStatusPayload {
    webshopId: string;
    activeStatus: 'active' | 'inactive';
}
export interface UpdateActiveTranslationLanguages {
    webshopId: string;
    languages: string[];
}

export interface UpdateWebshopExtraProcessingTimesPayload {
    webshopId: string;
    wholesaleStockExtraProcessingTimeInMinutes: number;
    localStockExtraProcessingTimeInMinutes: number;
}

export interface UpdateWebshopCategoriesSequencePayload {
    webshopId: string;
    categoryIds: string[];
}

export interface UpdateWebshopCartRestrictionsPayload {
    webshopId: string;
    shoppingCartRestrictions: CartRestriction[];
}
