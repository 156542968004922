import * as DeviceInterface from 'meditech-device-interface'
import { DeviceLog, log, VendingMachineLog } from 'logger';

import PlcMessageResolver from './Infrastructure/LocalComm/PlcMessageResolver';
import LocalComm from './Infrastructure/LocalComm/LocalComm';
import MeditechDevice from './MeditechDevice';
import {
    PrintscreenStatus,
    DeviceSettings,
    wwks2StockInfoMessage,
    DeviceStatus,
    PlcStatus,
    Wwks2ProcessStatus,
    wwks2OutputMessage,
    wwks2StatusResponse
} from './Domain';
import Wwks2Comm from './Infrastructure/Wwks2Comm/Wwks2Comm';
import { Wwks2CommSettings } from './Infrastructure/Wwks2Comm/Wwks2CommSettings';
import { CancellablePromise } from './Infrastructure/CancellablePromise';


export default class DeviceApi implements DeviceInterface.IDeviceApi {
    private readonly LOCALCOMMIGHTBEDEADTHRESHOLD: number = 5 * 60;
    private readonly MIN_LOCALCOM_VERSION_TO_ENABLE_LOCALCOM_CAMERA_API = 21

    public DeviceEventEmitter: DeviceInterface.DeviceEventEmitter;
    public DeliveryEventEmitter: DeviceInterface.DeliveryEventEmitter;
    public ProofEventEmitter: DeviceInterface.ProofEventEmitter;
    public StockEventEmitter: DeviceInterface.StockEventEmitter;

    private readonly _plcMessageResolver: PlcMessageResolver;
    private _stockSource: Wwks2Comm;
    private _storageSystem: Wwks2Comm;

    private readonly localCommWebSocketBaseUrl: string;
    private readonly localComm: LocalComm;
    private readonly meditechDevice: MeditechDevice;
    private readonly handleVideoCallsThroughLocalcomm: boolean;
    private localComVersion: number;
    private deviceSettings: DeviceSettings;
    private deviceType: keyof typeof DeviceInterface.IDeviceType 
        = DeviceInterface.IDeviceType.UNKNOWN;
    private isCollect = false;

    private hasThirdPartyStockSource = false;
    private robotTransportTime = 0;
    private shouldConveyorMoveWhenDelivering = false;
    private inNightHatchMode = false;
    private preConfirmedDeliveryResponse: DeviceInterface.IDeliveryResponse;
    private cancellableDelivery: CancellablePromise<any> | null = null;
    private hasDelivered = false;
    private deviceInErrorState = false;
    private storageSystemInErrorState = false;
    private shouldRejectOnRecovery = false;
    private disableStorageSystem = false;
    private disableMonitoring = false;
    private plcLastIMode: string = 'auto';

    private localcomMightBeDeadCounter = 0;
    
    constructor(
        localCommWebSocketBaseUrl: string,
        handleVideoCallsThroughLocalcomm: boolean
    ) {
        this.DeviceEventEmitter = new DeviceInterface.DeviceEventEmitter();
        this.DeliveryEventEmitter = new DeviceInterface.DeliveryEventEmitter();
        this.ProofEventEmitter = new DeviceInterface.ProofEventEmitter();
        this.StockEventEmitter = new DeviceInterface.StockEventEmitter();
        
        this._plcMessageResolver = new PlcMessageResolver();

        this.localCommWebSocketBaseUrl = localCommWebSocketBaseUrl;
        this.localComm = new LocalComm(this.localCommWebSocketBaseUrl);
        this.meditechDevice = new MeditechDevice(
            this.localComm
        );

        this.handleVideoCallsThroughLocalcomm = handleVideoCallsThroughLocalcomm;

        this.meditechDevice.SetOnBarcodeIsReaded(
            (barcode: string, timestamp: number) => {

                timestamp;
                const scanEvent: DeviceInterface.IScanEvent = {
                    Code: barcode
                }

                this.DeviceEventEmitter.emit(
                    DeviceInterface.DeviceEvent.Scanned, scanEvent) }
        )

        this.localComm.SetOnPLCMessageCallback(
            async (plcHandleValue) => {

                if (undefined === plcHandleValue.MTMATIC?.status?.mode) {
                    return;
                }

                if (plcHandleValue.MTMATIC?.status?.mode.toLocaleLowerCase().includes("armed")) {
                    await this.localComm.PlcSoftware2Auto();
                    return;
                }
                
                if (plcHandleValue.MTMATIC?.status?.mode.toLocaleLowerCase().includes("fatal")) {
                    log('error', 'DEVICE_PLC_MODE - ' + plcHandleValue.MTMATIC?.status?.mode)
                    this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.EmergencyCircuitInterrupted);
                }

                if (plcHandleValue.MTMATIC?.status?.mode.toLocaleLowerCase().includes("ready")) {
                    log('error', 'DEVICE_PLC_MODE - ' + plcHandleValue.MTMATIC?.status?.mode)
                    if (!this.plcLastIMode?.includes("fatal")) {
                        this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.EmergencyCircuitInterrupted);
                    }
                }
            
                this.plcLastIMode = plcHandleValue.MTMATIC?.status?.mode;
                
                if (undefined === plcHandleValue.messages) {
                    return;
                }

                for (const plcMessage of plcHandleValue.messages) {
                    const resolvedPlcMessage =
                        this._plcMessageResolver.Resolve(plcMessage.code.toString())

                    if (3 > resolvedPlcMessage.Severity) {
                        log('error', 'DEVICE_PLC_MESSAGE_' + resolvedPlcMessage.Code, resolvedPlcMessage)
                    } else if (4 > resolvedPlcMessage.Severity) {
                        log('warn', 'DEVICE_PLC_MESSAGE_' + resolvedPlcMessage.Code, resolvedPlcMessage)
                    } else {
                        log('info', 'DEVICE_PLC_MESSAGE_' + resolvedPlcMessage.Code, resolvedPlcMessage)
                    }
                }
            }
        );
    }

    public SetLocalcomDisabled(disabled: boolean) {
        if (disabled) {
            this.localComm.Disable();
        }
        else {
            this.localComm.Enable();
        }
    }

    public GetLocalcomIsDisabled() {
        return this.localComm.IsDisabled();
    }

    public getLocalCommInstance() {
        return this.localComm;
    }

    public async ClearState(): Promise<void> {
        this.hasDelivered = false;
        this.deviceInErrorState = false;
        this.SetNightHatchMode(false);
    }
    
    public async SetDisableMonitoring(disableMonitoring: boolean) {
        this.disableMonitoring = disableMonitoring;
    }

    public async SetNightHatchMode(nightHatchMode: boolean) {
        this.deviceInErrorState = false;
        this.meditechDevice.SetMaticDeviceInUse(nightHatchMode);
        this.inNightHatchMode = nightHatchMode;

        if (this.inNightHatchMode) {
            this.meditechDevice.SwitchLedDeliveryBoxOn();
        } else {
            this.meditechDevice.SwitchLedDeliveryBoxOff();
        }
    }

    public async GetDeviceInformation(): 
        Promise<DeviceInterface.IDeviceInformation> {
        const deviceInformation: DeviceInterface.IDeviceInformation = {
            DeviceId: '',
            DeviceState: DeviceInterface.IStateType.INITIALISING
        };
        
        if (!this.meditechDevice.GetInitializationStatus().localcommConnected) {
            this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Discovering);
            await this.localComm.Listen(() => {});
            await this.meditechDevice.initLocalComm();
            await this.localComm.SendWatchdogStart();
        }

        const deviceStatus = this.meditechDevice.GetMaticDeviceStatus();
        deviceInformation.DeviceId = deviceStatus.device.network.mac;

        if (deviceInformation.DeviceId.toLocaleLowerCase() === '1c:69:7a:06:85:0c') {
            await this.localComm.SendWatchdogStop();   
        }
        
        if ((await this.meditechDevice.IsVisionReadyForDelivery()).status 
        || (await this.meditechDevice.IsMaticReadyForDelivery()).status) {
            deviceInformation.DeviceState = DeviceInterface.IStateType.READY;
        } else {
            this.DeviceEventEmitter.emit(
                DeviceInterface.DeviceEvent.AwaitingConfiguration, deviceInformation);
            this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Engaged);
        }

        return deviceInformation;
    }

    public async InitialiseDevice(
        deviceConfiguration: DeviceInterface.IDeviceConfiguration): 
        Promise<boolean> {     
        
        if (this.GetLocalcomIsDisabled()) {
            return false;
        }
            
        let deviceInitialisationStatus: { status: boolean; msg: string; errorMsg: string[]; } = {
            status: false,
            msg: '',
            errorMsg: []
        }

        const deviceInformation: DeviceInterface.IDeviceInformation = 
            await this.GetDeviceInformation();

        DeviceLog.setDeviceMac(deviceInformation.DeviceId);

        this.deviceType = deviceConfiguration.DeviceType;
        this.isCollect = deviceConfiguration.IsCollect;
        this.robotTransportTime = deviceConfiguration.RobotTransportTime!;
        this.shouldConveyorMoveWhenDelivering = deviceConfiguration.IsMaticSlim;
        this.disableStorageSystem = deviceConfiguration.SimulateStorageSystem;
        this.disableMonitoring = deviceConfiguration.DisableMonitoring;  

        this.deviceSettings = {
            deviceId: deviceInformation.DeviceId,
            barcodeScannerAvailable: deviceConfiguration.HasScanner,
            delivery: {
                stockLocation: deviceConfiguration.RobotStockLocation!,
                zone: deviceConfiguration.RobotZone!,
                deliveryOutput: deviceConfiguration.RobotDeliveryExit!,
                deliveryOutputIsMaticHatch: !deviceConfiguration.RobotDeliveryExitIsNotMaticHatch!,
                deliveryCameraIp: deviceConfiguration.DeliveryCameraIp || undefined
            },
            storageSystem: {
                useRobotStorageSystem: deviceConfiguration.HasRobotConnection,
                IP: deviceConfiguration.RobotIpPort!
            },
            disablePLC: deviceConfiguration.DisablePLCConnection || deviceConfiguration.RobotDeliveryExitIsNotMaticHatch
        };

        this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Initialising);

        const storageSystemWwks2CommSettings: Wwks2CommSettings = {
            ConnectionString: deviceConfiguration.RobotIpPort!,
            SourceId: 850,
            StockLocations: deviceConfiguration.RobotStockLocation!,
            Zone: deviceConfiguration.RobotZone!,
            Exit: deviceConfiguration.RobotDeliveryExit!
        }

        this._storageSystem = new Wwks2Comm(storageSystemWwks2CommSettings);
        if (null != deviceConfiguration.PharmacySoftwareIpPort &&
            '' !== deviceConfiguration.PharmacySoftwareIpPort) {
            const pharmacySoftwareWwks2CommSettings: Wwks2CommSettings = {
                ConnectionString: deviceConfiguration.PharmacySoftwareIpPort!,
                SourceId: 850,
                StockLocations: [ 'Available' ],
                Zone: deviceConfiguration.RobotZone!,
                Exit: deviceConfiguration.RobotDeliveryExit!
            }

            this.hasThirdPartyStockSource = true;
            this._stockSource = new Wwks2Comm(pharmacySoftwareWwks2CommSettings)!;
        }

        if (this.hasThirdPartyStockSource) {
            this._stockSource.SetOnStockChangeCallback(
                async (stockMutationMessage: wwks2StockInfoMessage) => {
                    const articles = new Array<{ArticleCode: string, Quantity: number}>();
                    for (const article of stockMutationMessage.articles) {
                        articles.push({ ArticleCode: article.id, Quantity: article.quantity ? parseInt(article.quantity) : 0 })
                    }
    
                    this.StockEventEmitter.emit(
                        DeviceInterface.StockEvent.StockMutation, { Articles: articles })
                }
            );
        } else {
            this._storageSystem.SetOnStockChangeCallback(
                async (stockMutationMessage: wwks2StockInfoMessage) => {
                    const articles = new Array<{ArticleCode: string, Quantity: number}>();
                    for (const article of stockMutationMessage.articles) {
                        articles.push({ ArticleCode: article.id, Quantity: article.quantity ? parseInt(article.quantity) : 0 })
                    }
    
                    this.StockEventEmitter.emit(
                        DeviceInterface.StockEvent.StockMutation, { Articles: articles })
                }
            );
        }

        if (DeviceInterface.IDeviceType.VISION === deviceConfiguration.DeviceType) {
            deviceInitialisationStatus = await this.meditechDevice.StartVision(
                this.deviceSettings, () => {});

            if (this.disableStorageSystem || !this.deviceSettings.storageSystem?.useRobotStorageSystem) {
                deviceInitialisationStatus.status = true;
            } else {
                await this._storageSystem.Connect(false);
                if (this.hasThirdPartyStockSource) {
                    await this._stockSource.Connect(false);
                }
            }
        }

        if (DeviceInterface.IDeviceType.MATIC === deviceConfiguration.DeviceType) {
            deviceInitialisationStatus = await this.meditechDevice.StartMatic(
                this.deviceSettings, () => {});

            if (!this.disableStorageSystem) {
                await this._storageSystem.Connect(false);
                if (this.hasThirdPartyStockSource) {
                    await this._stockSource.Connect(false);
                }
            }
        }

        if (this.isCollect) {
            deviceInitialisationStatus.status = true;
        }

        if (deviceInitialisationStatus.status) {
            this.localComVersion = Number(
                this.meditechDevice
                .GetMaticDeviceStatus().device.version
                .substring(0, 3).split('.').join('')
            );

            this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.Finalising);
            await this.localComm.ReadBoxDetectCounter();
            const boxesDetected =  await this.localComm.GetBoxDetectCounterPlc();
            if (0 < boxesDetected) {
                await this.meditechDevice.StartReject();
            }
            this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Ready);
            log('info', 'DEVICE_HARDWARE_READY');
        }
        else {
            this.deviceInErrorState = true;
            VendingMachineLog.hardwareFault('DEVICE_HARDWARE_NOT_READY ' + JSON.stringify(deviceInitialisationStatus.errorMsg));
            VendingMachineLog.hardwareStatus(JSON.stringify(this.meditechDevice.GetMaticDeviceStatus()));
            this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Faulted);
        }
        
        if ((this.isCollect && !this.deviceSettings.storageSystem?.useRobotStorageSystem) || this.disableStorageSystem) {
            this.deviceSettings.disablePLC = true;
        }

        if (this.deviceSettings.disablePLC) {
            this.SetLightIndicator(0, 255, 0);
            deviceInitialisationStatus.status = true;
            this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Ready);
            return deviceInitialisationStatus.status
        }

        this.meditechDevice.SetOnDeviceDiagnoseCallback(
            (deviceStatus: DeviceStatus) =>  {
                this.monitor(deviceStatus); });

        return deviceInitialisationStatus.status
    }

    public ScreenIsOn(): boolean {
        return this.localComm.ScreenIsOn();
    }

    public ScreenIsOnUnknown(): boolean {
        return this.localComm.ScreenIsOnUnknown();
    }

    public async EnableScreen(): Promise<boolean> {
        this.localComm.SendScreenEnable();
        return true;
    }

    public async DisableScreen(): Promise<boolean> {
        this.localComm.SendScreenDisable();
        return true;
    }
    
    public async EnableScanner(): Promise<boolean> {
        this.localComm.SendBarcodeScannerEnable();
        return true;
    }

    public async DisableScanner(): Promise<boolean> {
        this.localComm.SendBarcodeScannerDisable(); 
        return true;
    }

    public async EnsureSipConfigured(sipAccount: DeviceInterface.ISipAccount): Promise<DeviceInterface.ISipAccount> {
        if (this.handleVideoCallsThroughLocalcomm) {
            return await this.localComm.EnsureSipConfigured(sipAccount) as DeviceInterface.ISipAccount;
        }

        else if (this.localComVersion >= this.MIN_LOCALCOM_VERSION_TO_ENABLE_LOCALCOM_CAMERA_API) {
            sipAccount.Id = '0';
            return sipAccount;
        }

        else {
            const deliveryCameraIp = this.meditechDevice.settings.delivery?.deliveryCameraIp || '200.0.0.10';
            const response = await fetch('http://' + deliveryCameraIp + ':5000' + '/call-ensureSipConfigured', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(sipAccount)  
            });

            const data = await response.json();
            return {
                ...data,
                Id: data['id']
            } as DeviceInterface.ISipAccount;
        }
    }

    public async Call(sipCall: DeviceInterface.ISipCall): Promise<DeviceInterface.ISipCall> {
        if (this.handleVideoCallsThroughLocalcomm) {
            return await this.localComm.Call(sipCall) as DeviceInterface.ISipCall;
        }

        else if (this.localComVersion >= this.MIN_LOCALCOM_VERSION_TO_ENABLE_LOCALCOM_CAMERA_API) {
            const response = await fetch('http://localhost/call/start');
            const data = await response.json();
            return { ...data, CallId: data['call_id'] } as DeviceInterface.ISipCall;
        }

        else {
            const deliveryCameraIp = this.meditechDevice.settings.delivery?.deliveryCameraIp || '200.0.0.10';
            const response =  await fetch('http://' + deliveryCameraIp + ':5000' + '/call', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(sipCall) 
            });

            const data = await response.json();
            return {
                ...data,
                CallId: data['callId']
            } as DeviceInterface.ISipCall;
        }
    }

    public async GetCallStatus(callId?: string): Promise<Array<DeviceInterface.ISipCall> | false> {
        if (this.handleVideoCallsThroughLocalcomm) {
            return await this.localComm.GetCallStatus(callId) as Array<DeviceInterface.ISipCall>;
        }

        else if (this.localComVersion >= this.MIN_LOCALCOM_VERSION_TO_ENABLE_LOCALCOM_CAMERA_API) {
            if (undefined === callId) {
                return new Array<DeviceInterface.ISipCall>();
            }

            const response = await fetch('http://localhost/call/status?call_id=' + callId);
            const data = await response.json();

            const sipCalls: Array<DeviceInterface.ISipCall> = new Array<DeviceInterface.ISipCall>();
            const sipCall: DeviceInterface.ISipCall = {
                CallId: data['message']['CallStatus']['CallId'],
                To: data['message']['CallStatus']['RemoteURI'],
                CallState: data['message']['CallStatus']['CallState']
            };

            sipCalls.push(sipCall)
            return sipCalls;
        }

        else {
            const deliveryCameraIp = this.meditechDevice.settings.delivery?.deliveryCameraIp || '200.0.0.10';
            const response = await fetch(
                callId
                    ? 'http://' + deliveryCameraIp + ':5000' + '/call/' + callId
                    : 'http://' + deliveryCameraIp + ':5000' + '/call'
                );

            let data = await response.json();
            data = data.map((item: any) => {
                return {
                    ...item,
                    CallId: item['callId'],
                    CallState: item['callState']
                }
            });

            return data as Array<DeviceInterface.ISipCall>;
        }
    }

    public async TerminateCall(callId: string): Promise<any> {
        if (this.handleVideoCallsThroughLocalcomm) {
            return await this.localComm.TerminateCall(callId);
        }

        else if (this.localComVersion >= this.MIN_LOCALCOM_VERSION_TO_ENABLE_LOCALCOM_CAMERA_API) {
            const response = await fetch('http://localhost/call/terminate?call_id=' + callId)
            return (await response.json()) as DeviceInterface.ISipCall;
        }

        else {
            const deliveryCameraIp = this.meditechDevice.settings.delivery?.deliveryCameraIp || '200.0.0.10';
            const response = await fetch('http://' + deliveryCameraIp + ':5000' + '/call/' + callId, {
                method: 'DELETE'
            })

            return (await response.json()) as DeviceInterface.ISipCall;
        }
    }

    private stockThreadLock = false;
    public async GetStockInformation(articleCodes?: Array<string>):
        Promise<DeviceInterface.IStockInformationResponse> {
        
        while(this.stockThreadLock) {
            await new Promise(res => setTimeout(res, 500));
        }
        
        this.stockThreadLock = true;
        const stockInformationResponse: DeviceInterface.IStockInformationResponse = {
            Success: false,
            Articles: Array<DeviceInterface.IArticleStockResponse>()
        }
        
        this.StockEventEmitter.emit(DeviceInterface.StockEvent.CheckingStock);       
        let stockInformationResult;
        if (null !== articleCodes && undefined !== articleCodes) {
            if (this.hasThirdPartyStockSource) {
                stockInformationResult = 
                    await this._stockSource.CheckProductStock(articleCodes);
            } else {
                stockInformationResult = 
                    await this._storageSystem.CheckProductStock(articleCodes);
            }
        } else {
            stockInformationResult = 
                await this._storageSystem.GetCompleteRobotStock(false);
        }

        if (stockInformationResult.status) {
            stockInformationResponse.Success = true;
            stockInformationResult.articles.forEach(a => 
                stockInformationResponse.Articles.push({
                    ArticleCode: a.barcode,
                    Quantity: a.quantity,
                    AvailableQuantity: parseInt(a.availableQuantity)
                })
            );
        }
        
        this.stockThreadLock = false;
        return stockInformationResponse;
    }

    public async Deliver(
        delivery: DeviceInterface.IDeliveryRequest, 
        outputDestination: number = this.deviceSettings.delivery?.deliveryOutput!):
        Promise<DeviceInterface.IDeliveryResponse> {

        this.meditechDevice.SetMaticDeviceInUse(true);
        
        const deliveryResponse: DeviceInterface.IDeliveryResponse = {
            Success: false,
            Status: 'incomplete',
            Proof: new Array<DeviceInterface.IPictureResponse>(),
            Log: new Array<string>()
        }

        this.preConfirmedDeliveryResponse = deliveryResponse;

        if (null === delivery.Articles || undefined === delivery.Articles) {
            log('error', 'Requested delivery contains no articles', delivery);
            this.meditechDevice.SetMaticDeviceInUse(false || this.inNightHatchMode);

            return deliveryResponse;
        }
        
        if (DeviceInterface.IDeviceType.MATIC == this.deviceType && this.deviceSettings.delivery?.deliveryOutputIsMaticHatch && this.deviceSettings.delivery?.deliveryOutput == outputDestination) {
            const pictureResult = await this.meditechDevice.TakePictureOfDeliveryBox(this.localComVersion >= this.MIN_LOCALCOM_VERSION_TO_ENABLE_LOCALCOM_CAMERA_API);
            if (pictureResult) {
                const proof: DeviceInterface.IPictureResponse = {
                    Base64EncodedImage: pictureResult as string,
                    PictureFormatType: DeviceInterface.IPictureFormatType.JPEG,
                    DeliveryStageType: DeviceInterface.IDeliveryStageType.INITIATED,
                    TimeStamp: new Date()
                };
                deliveryResponse.Proof.push(proof);
                this.ProofEventEmitter.emit(DeviceInterface.ProofEvent.DeliveryInitiated, proof);
            } else {
                log('warn', 'Failed to take picture of delivery box, continuing...');
            }
        }

        const articleCodesAndQuantities = new Array<{barcode: string, quantity: number}>()
        for (const article of delivery.Articles) {
            articleCodesAndQuantities.push({
                barcode: article.articleCode,
                quantity: article.quantity })
        }

        this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.PickingArticle);
        if (this._storageSystem.GetRobotExit() != outputDestination) {
            this._storageSystem.SetRobotExit(outputDestination);
        }

        const deliveryLogic = async () => {
            return await this._storageSystem.EjectProduct(articleCodesAndQuantities, false);
        };

        this.cancellableDelivery = new CancellablePromise(deliveryLogic);
        const deliveryResult = await this.cancellableDelivery.execute();
        this.cancellableDelivery = null;

        if ('completed' !== deliveryResult.status) {
            VendingMachineLog.hardwareFault('Storage system responded with: ' + deliveryResult.status);
            VendingMachineLog.hardwareStatus(JSON.stringify(deliveryResult));
            this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.Faulted);
            this.meditechDevice.SetMaticDeviceInUse(false || this.inNightHatchMode);

            return deliveryResponse;
        }

        if (DeviceInterface.IDeviceType.MATIC == this.deviceType && !this.inNightHatchMode) {         
            if (null != this.robotTransportTime && undefined != this.robotTransportTime) {
                await new Promise(f => setTimeout(f, this.robotTransportTime * 1000));
            }
        }

        this.hasDelivered = true;
        this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.RobotDelivered);

        if (!this.deviceSettings.delivery?.deliveryOutputIsMaticHatch || this.deviceSettings.delivery?.deliveryOutput != outputDestination) {
            deliveryResponse.Status = 'completed';
            deliveryResponse.Success = true;
            this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.DeviceDelivered);

            return deliveryResponse;
        }

        return await this.handleDeliveredArticles(deliveryResponse);
    }

    public async TrackDelivery(dialogueId: string): 
        Promise<DeviceInterface.IDeliveryResponse> {
        
        this.meditechDevice.SetMaticDeviceInUse(true);
        
        let deliveryResponse: DeviceInterface.IDeliveryResponse = {
            Success: false,
            Status: 'incomplete',
            Proof: new Array<DeviceInterface.IPictureResponse>(),
            Log: new Array<string>()
        }

        this.preConfirmedDeliveryResponse = deliveryResponse;

        if (DeviceInterface.IDeviceType.MATIC == this.deviceType) {
            const pictureResult = await this.meditechDevice.TakePictureOfDeliveryBox(this.localComVersion >= this.MIN_LOCALCOM_VERSION_TO_ENABLE_LOCALCOM_CAMERA_API);
            if (pictureResult) {
                const proof = {
                    Base64EncodedImage: pictureResult as string,
                    PictureFormatType: DeviceInterface.IPictureFormatType.JPEG,
                    DeliveryStageType: DeviceInterface.IDeliveryStageType.INITIATED,
                    TimeStamp: new Date()
                }
                deliveryResponse.Proof.push(proof);
                this.ProofEventEmitter.emit(DeviceInterface.ProofEvent.DeliveryInitiated, proof);
            } else {
                log('warn', 'Failed to take picture of delivery box, continuing...');
            }
        }

        this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.PickingArticle,
            { ArticlesToPick: 1, CurrentlyPicking: 1 });

        deliveryResponse = 
            await this.ensureDeliveryResponse(deliveryResponse, dialogueId);
        
        if (deliveryResponse.Success) {      
            if (DeviceInterface.IDeviceType.MATIC == this.deviceType && !this.inNightHatchMode) {         
                if (null != this.robotTransportTime && undefined != this.robotTransportTime) {
                    await new Promise(f => setTimeout(f, this.robotTransportTime * 1000));
                }
            }

            this.hasDelivered = true;
            this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.RobotReservationDelivered);
            return await this.handleDeliveredArticles(deliveryResponse);
        }

        VendingMachineLog.hardwareFault('Delivery failed');
        VendingMachineLog.hardwareStatus(JSON.stringify(this.meditechDevice.GetMaticDeviceStatus()));
        this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.Faulted);
        this.meditechDevice.SetMaticDeviceInUse(false || this.inNightHatchMode);

        return deliveryResponse;
    }

    public async ConfirmDelivery(): 
        Promise<DeviceInterface.IDeliveryResponse> {        
        
        this.meditechDevice.SetMaticDeviceInUse(true);
        const deliveryResponse = this.preConfirmedDeliveryResponse;

        if (!this.deviceSettings.delivery?.deliveryOutputIsMaticHatch) {
            deliveryResponse.Status = 'completed';
            deliveryResponse.Success = true;
            this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.Completed);

            this.meditechDevice.SetMaticDeviceInUse(false);
            return deliveryResponse;
        }
        
        let closeCustomerHatchStatus = false;
        this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.ClosingCustomerHatch);
        do {
            closeCustomerHatchStatus = (await this.meditechDevice.CloseDeliveryDoor()).status;
            if (!closeCustomerHatchStatus) {
                VendingMachineLog.hardwareFault('Failed to close customer hatch');
                VendingMachineLog.hardwareStatus(JSON.stringify(this.meditechDevice.GetMaticDeviceStatus()));
                this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.CustomerHatchBlocked);
                await new Promise(res => setTimeout(res, 10000));
            }
        }
        while (!closeCustomerHatchStatus)

        const clientConfirmedPictureResult = await this.meditechDevice.TakePictureOfDeliveryBox(this.localComVersion >= this.MIN_LOCALCOM_VERSION_TO_ENABLE_LOCALCOM_CAMERA_API);
        if (clientConfirmedPictureResult) {
            const proof = {
                Base64EncodedImage: clientConfirmedPictureResult as string,
                PictureFormatType: DeviceInterface.IPictureFormatType.JPEG,
                DeliveryStageType: DeviceInterface.IDeliveryStageType.CLIENTCONFIRMED,
                TimeStamp: new Date()
            }
            deliveryResponse.Proof.push(proof);
            this.ProofEventEmitter.emit(DeviceInterface.ProofEvent.ClientConfirmed, proof);          
        } else {
            log('warn', 'Failed to take picture of delivery box, continuing...');
        }

        this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.Finalising);
        const rejectResult = (await this.meditechDevice.StartReject()).status;
        if (!rejectResult) {
            VendingMachineLog.hardwareFault('Failed to reject');
            VendingMachineLog.hardwareStatus(JSON.stringify(this.meditechDevice.GetMaticDeviceStatus()));
            // this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Faulted);
            // this.meditechDevice.SetMaticDeviceInUse(false || this.inNightHatchMode);

            // return deliveryResponse;
        }

        await this.localComm.ResetBoxDetectCounter();
        const finalisedPictureResult = await this.meditechDevice.TakePictureOfDeliveryBox(this.localComVersion >= this.MIN_LOCALCOM_VERSION_TO_ENABLE_LOCALCOM_CAMERA_API);
        if (finalisedPictureResult) {
            const proof = {
                Base64EncodedImage: finalisedPictureResult as string,
                PictureFormatType: DeviceInterface.IPictureFormatType.JPEG,
                DeliveryStageType: DeviceInterface.IDeliveryStageType.FINALISED,
                TimeStamp: new Date()
            }
            deliveryResponse.Proof.push(proof);
            this.ProofEventEmitter.emit(DeviceInterface.ProofEvent.Finalised, proof); 
        } else {
            log('warn', 'Failed to take picture of delivery box, continuing...');
        }

        this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.Completed);
        await this.ClearState();

        return deliveryResponse;
    }
    
    // - sending no endpoint and no filename = base64 image data is returned
    // - sending endpoint and filename = image is POSTed to endpoint
    // - sending no endpoint and filename = image is POSTed to hardcoded legacy endpoint
    public async PrintScreen(endpoint: string, filename: string): 
        Promise<DeviceInterface.IPrintScreenResponse> {
        const printScreenResponse: DeviceInterface.IPrintScreenResponse = {
            Success: false
        }

        const printScreenStatus = 
            await this.localComm.SendTakePrintscreen(endpoint, filename);
        if ('object' === typeof printScreenStatus.valueOf()) {
            printScreenResponse.Success = 
                '200' === (printScreenStatus as PrintscreenStatus).statusCode;
            printScreenResponse.ImageData =
                (printScreenStatus as PrintscreenStatus).imageData;
        }

        return printScreenResponse;
    }
    
    public async RestartSoftware(): Promise<boolean> {
        log('warn', 'Restarting software...');
        this.localComm.SendWatchdogStop();
        this.localComm.SendPcSoftwareReboot();
        return true;
    }

    public async RebootPC(): Promise<boolean> {
        log('warn', 'Rebooting pc...');
        this.localComm.SendWatchdogStop();
        this.localComm.SendPcReboot();
        return true;
    }

    private lastDeviceHardwareStatus: DeviceInterface.IDeviceHardwareStatus;
    public async GetDeviceHardwareStatus(): Promise<DeviceInterface.IDeviceHardwareStatus> {
        const plcStatus = ((await this.localComm.GetPlcStatus()) as PlcStatus);

        const deviceHardwareStatus: DeviceInterface.IDeviceHardwareStatus = {
            CustomerHatchState: DeviceInterface.IHatchStateType.CLOSED,
            PharmacistHatchState: DeviceInterface.IHatchStateType.CLOSED,
            ConveyorState: DeviceInterface.IConveyorStateType.IDLE,
            LightsState: plcStatus.status.led ? DeviceInterface.ILightsStateType.ON : DeviceInterface.ILightsStateType.OFF
        }

        if (undefined == this.lastDeviceHardwareStatus) {
            this.lastDeviceHardwareStatus = deviceHardwareStatus;
        }

        if (-1 != plcStatus.plcMessagesCodes.indexOf(602) && (
            DeviceInterface.IHatchStateType.CLOSED == this.lastDeviceHardwareStatus.CustomerHatchState ||
            DeviceInterface.IHatchStateType.OPENING == this.lastDeviceHardwareStatus.CustomerHatchState)) {
                deviceHardwareStatus.CustomerHatchState = DeviceInterface.IHatchStateType.OPENING;
        }
        else if (-1 != plcStatus.plcMessagesCodes.indexOf(602) && (
            DeviceInterface.IHatchStateType.OPENED == this.lastDeviceHardwareStatus.CustomerHatchState ||
            DeviceInterface.IHatchStateType.CLOSING == this.lastDeviceHardwareStatus.CustomerHatchState)) {
                deviceHardwareStatus.CustomerHatchState = DeviceInterface.IHatchStateType.CLOSING;
        }
        else if (-1 != plcStatus.plcMessagesCodes.indexOf(521)) {
            deviceHardwareStatus.CustomerHatchState = DeviceInterface.IHatchStateType.OPENED;
        }
        else {
            deviceHardwareStatus.CustomerHatchState = DeviceInterface.IHatchStateType.CLOSED;
        }

        if (-1 != plcStatus.plcMessagesCodes.indexOf(230) || -1 != plcStatus.plcMessagesCodes.indexOf(901)) {
            deviceHardwareStatus.PharmacistHatchState = DeviceInterface.IHatchStateType.UNKNOWN;
        }
        else if (-1 != plcStatus.plcMessagesCodes.indexOf(603) && (
            DeviceInterface.IHatchStateType.CLOSED == this.lastDeviceHardwareStatus.PharmacistHatchState ||
            DeviceInterface.IHatchStateType.OPENING == this.lastDeviceHardwareStatus.PharmacistHatchState)) {
                deviceHardwareStatus.PharmacistHatchState = DeviceInterface.IHatchStateType.OPENING;
        }
        else if (-1 != plcStatus.plcMessagesCodes.indexOf(603) && (
            DeviceInterface.IHatchStateType.OPENED == this.lastDeviceHardwareStatus.PharmacistHatchState ||
            DeviceInterface.IHatchStateType.CLOSING == this.lastDeviceHardwareStatus.PharmacistHatchState)) {
                deviceHardwareStatus.PharmacistHatchState = DeviceInterface.IHatchStateType.CLOSING;
        }
        else if (-1 != plcStatus.plcMessagesCodes.indexOf(522)) {
            deviceHardwareStatus.PharmacistHatchState = DeviceInterface.IHatchStateType.OPENED;
        }
        else {
            deviceHardwareStatus.PharmacistHatchState = DeviceInterface.IHatchStateType.CLOSED;
        }

        deviceHardwareStatus.ConveyorState = this.lastDeviceHardwareStatus.ConveyorState;
        this.lastDeviceHardwareStatus = deviceHardwareStatus;

        return deviceHardwareStatus;
    }

    public async OpenCustomerHatch(): Promise<boolean> {
        if (DeviceInterface.IHatchStateType.CLOSED != (await this.GetDeviceHardwareStatus()).PharmacistHatchState) {
            log('warn', 'Pharmacist hatch opened, closing...');
            await this.meditechDevice.CloseRearDoor();
        }
        const openCustomerHatchResult = await this.meditechDevice.OpenDeliveryDoor();
        return openCustomerHatchResult.status;
    }

    public async CloseCustomerHatch(): Promise<boolean> {
        if (this.disableStorageSystem) {
            return true;
        }
        const closeCustomerHatchResult = await this.meditechDevice.CloseDeliveryDoor();
        return closeCustomerHatchResult.status;
    }

    public async Inject(): Promise<boolean> {
        if (DeviceInterface.IHatchStateType.CLOSED != (await this.GetDeviceHardwareStatus()).CustomerHatchState) {
            log('warn', 'Customer hatch opened, closing...');
            await this.meditechDevice.CloseDeliveryDoor();
        }
        this.lastDeviceHardwareStatus.ConveyorState = DeviceInterface.IConveyorStateType.MOVINGFORWARD;
        const moveConveyorForwardResult = await this.meditechDevice.ForwardCVAndCloseRearDoor();
        this.lastDeviceHardwareStatus.ConveyorState = DeviceInterface.IConveyorStateType.IDLE
        return moveConveyorForwardResult.status;
    }

    public async Reject(): Promise<boolean> {
        if (DeviceInterface.IHatchStateType.CLOSED != (await this.GetDeviceHardwareStatus()).CustomerHatchState) {
            log('warn', 'Customer hatch opened, closing...');
            await this.meditechDevice.CloseDeliveryDoor();
        }
        this.lastDeviceHardwareStatus.ConveyorState = DeviceInterface.IConveyorStateType.MOVINGBACKWARD;
        const rejectResult = await this.meditechDevice.StartReject();
        this.lastDeviceHardwareStatus.ConveyorState = DeviceInterface.IConveyorStateType.IDLE
        return rejectResult.status;
    }

    public async OpenPharmacistHatch(): Promise<boolean> {
        if (DeviceInterface.IHatchStateType.CLOSED != (await this.GetDeviceHardwareStatus()).CustomerHatchState) {
            log('warn', 'Customer hatch opened, closing...');
            await this.meditechDevice.CloseDeliveryDoor();
        }
        const openPharmacistHatchResult = await this.meditechDevice.OpenRearDoor();
        return openPharmacistHatchResult.status;
    }

    public async ClosePharmacistHatch(): Promise<boolean> {
        this.lastDeviceHardwareStatus.ConveyorState = DeviceInterface.IConveyorStateType.MOVINGBACKWARD;
        setTimeout(() => this.lastDeviceHardwareStatus.ConveyorState = DeviceInterface.IConveyorStateType.IDLE, 10000);
        const closePharmacistHatchResult = await this.meditechDevice.CloseRearDoor();
        return closePharmacistHatchResult.status;
    }

    public async LightsOn(): Promise<boolean> {
        await this.meditechDevice.SwitchLedDeliveryBoxOn();
        return true;
    }

    public async LightsOff(): Promise<boolean> {
        await this.meditechDevice.SwitchLedDeliveryBoxOff();
        return true;
    }

    public async TakePictureOfDeliveryBox(): Promise<false | string> {
        return this.meditechDevice.TakePictureOfDeliveryBox(this.localComVersion >= this.MIN_LOCALCOM_VERSION_TO_ENABLE_LOCALCOM_CAMERA_API);
    }

    private async ensureDeliveryResponse(
        deliveryResponse: DeviceInterface.IDeliveryResponse, 
        dialogueId: string):
        Promise<DeviceInterface.IDeliveryResponse> {
        
        let shouldBreak = false
        do {
            await new Promise(res => setTimeout(res, 5000));
            const processStatus = this._storageSystem.GetProcessStatus(dialogueId);
            if (false !== processStatus) {
                shouldBreak = true;
                deliveryResponse.Status =
                    ((processStatus as Wwks2ProcessStatus).data.message as wwks2OutputMessage).status;
                deliveryResponse.Success = 'completed' === deliveryResponse.Status;
            } else {
                log('info', 'DEVICE_DELIVERY',
                    {Info: 'Awaiting status for the delivery-process with id: ' + dialogueId})
            }
        }
        while (!shouldBreak)

        return deliveryResponse;
    }
    
    private async handleDeliveredArticles(
        deliveryResponse: DeviceInterface.IDeliveryResponse):
        Promise<DeviceInterface.IDeliveryResponse> {
        
        this.meditechDevice.SetMaticDeviceInUse(true);
        
        if (DeviceInterface.IDeviceType.VISION == this.deviceType) {
            this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.MovingConveyor);
            this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.OpeningFrontDoor);
        }
        
        else if (DeviceInterface.IDeviceType.MATIC == this.deviceType && !this.inNightHatchMode) {
            if (this.shouldConveyorMoveWhenDelivering) {
                this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.MovingConveyor);
                const forwardConveyorStatus = (await this.meditechDevice.ForwardCVAndCloseRearDoor()).status;
                if (!forwardConveyorStatus) {
                    deliveryResponse.Status = 'faulted';
                    deliveryResponse.Success = false;
                    VendingMachineLog.hardwareFault('Failed to move conveyor forward');
                    VendingMachineLog.hardwareStatus(JSON.stringify(this.meditechDevice.GetMaticDeviceStatus()));
                    this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.Faulted);
                    this.meditechDevice.SetMaticDeviceInUse(false || this.inNightHatchMode);
                    return deliveryResponse;
                }  
            }

            const pictureResult = await this.meditechDevice.TakePictureOfDeliveryBox(this.localComVersion >= this.MIN_LOCALCOM_VERSION_TO_ENABLE_LOCALCOM_CAMERA_API);
            if (pictureResult) {
                const proof = {
                    Base64EncodedImage: pictureResult as string,
                    PictureFormatType: DeviceInterface.IPictureFormatType.JPEG,
                    DeliveryStageType: DeviceInterface.IDeliveryStageType.ROBOTDELIVERED,
                    TimeStamp: new Date()
                }
                deliveryResponse.Proof.push(proof);
                this.ProofEventEmitter.emit(DeviceInterface.ProofEvent.RobotDelivered, proof);
            } else {
                log('warn', 'Failed to take picture of delivery box, continuing...');
            }
    
            this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.OpeningFrontDoor);
            let openDoorResult = (await this.meditechDevice.OpenDeliveryDoor()).status;
            if (!openDoorResult) {
                await new Promise(f => setTimeout(f, 3 * 1000));
                openDoorResult = (await this.meditechDevice.OpenDeliveryDoor()).status;
            }
            if (!openDoorResult) {
                deliveryResponse.Status = 'faulted';
                deliveryResponse.Success = false;
                VendingMachineLog.hardwareFault('Failed to open customer hatch');
                VendingMachineLog.hardwareStatus(JSON.stringify(this.meditechDevice.GetMaticDeviceStatus().plc.status));
                this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.Faulted);
                this.meditechDevice.SetMaticDeviceInUse(false || this.inNightHatchMode); 
                             
                return deliveryResponse;
            }
        }

        deliveryResponse.Status = 'completed';
        deliveryResponse.Success = true;
        this.preConfirmedDeliveryResponse = deliveryResponse;
        this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.DeviceDelivered);
        
        if (DeviceInterface.IDeviceType.VISION == this.deviceType) {
            this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.ClosingCustomerHatch);
            this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.Finalising);
            this.DeliveryEventEmitter.emit(DeviceInterface.DeliveryEvent.Completed);
            this.meditechDevice.SetMaticDeviceInUse(false);
        }

        return deliveryResponse;
    }

    public async SetLightIndicator(red: number, green: number, blue: number): Promise<boolean> {
        if (this.localComm == undefined) {
            return Promise.resolve(false);
        }
        
        const rgb = [red, green, blue];
        const rgbString = '"[' + rgb.join(', ') + ']"';
        const response = await this.localComm.SetLightIndicator(rgb);
        if (response.command?.rgb_color && !response.command?.rgb_color.includes(rgbString)) {
            return Promise.resolve(false);
        }

        return Promise.resolve(true);
    }

    public async Print(url: string): Promise<boolean> {
        if (this.localComm == undefined) {
            return Promise.resolve(false);
        }
        
        const response = await this.localComm.Print(url);
        if (!response.command.error.includes('0')) {
            return Promise.resolve(false);
        }

        return Promise.resolve(true);
    }

    private async monitor(deviceStatus: DeviceStatus) {
        if (this.disableMonitoring) {
            return;
        }
        
        if (!this.meditechDevice.IsMaticDeviceInUse()) {

            if (null === (deviceStatus.plc.status as PlcStatus) ||
                undefined === (deviceStatus.plc.status as PlcStatus)) {
                if (!this.deviceInErrorState) {
                    this.deviceInErrorState = true;
                    log('error', 'LOCALCOM_ADS', {Problem: 'Not receiving PLC status information from ADS'})
                    this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Faulted);
                }

                this.localcomMightBeDeadCounter += 5;
                if (this.localcomMightBeDeadCounter >= this.LOCALCOMMIGHTBEDEADTHRESHOLD) {
                    await this.RebootPC();
                }

                return;
            }

            const plcStatus = (deviceStatus.plc.status as PlcStatus).status;
            if (null === plcStatus || undefined === plcStatus) {
                if (!this.deviceInErrorState) {
                    this.deviceInErrorState = true;
                    log('error', 'LOCALCOM_ADS', {Problem: 'Not receiving PLC status information from ADS'})
                    this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Faulted);
                }

                this.localcomMightBeDeadCounter += 5;
                if (this.localcomMightBeDeadCounter >= this.LOCALCOMMIGHTBEDEADTHRESHOLD) {
                    await this.RebootPC();
                }

                return;
            }

            if (DeviceInterface.IDeviceType.MATIC == this.deviceType && (!plcStatus.plcDevice.localCommIsConnectedWithPLC || plcStatus.plcDevice.adsState.toLowerCase().includes('noinfo'))) {
                if (!this.deviceInErrorState) {
                    this.deviceInErrorState = true;
                    log('error', 'LOCALCOM_ADS', {Problem: 'Not receiving PLC status information from ADS'})
                    this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Faulted);
                }

                this.localcomMightBeDeadCounter += 5;
                if (this.localcomMightBeDeadCounter >= this.LOCALCOMMIGHTBEDEADTHRESHOLD) {
                    await this.RebootPC();
                }

                return;
            }

            const maticStatus = (deviceStatus.plc.status as PlcStatus).MTMATIC;
            if (null === maticStatus || undefined === maticStatus) {
                if (!this.deviceInErrorState) {
                    this.deviceInErrorState = true;
                    log('error', 'LOCALCOM_STATUS', {Problem: 'Failed to read the vending machine status', Solution: 'Make sure localcom identifies as a vending machine'})
                    this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Faulted);
                }

                this.localcomMightBeDeadCounter += 5;
                if (this.localcomMightBeDeadCounter >= this.LOCALCOMMIGHTBEDEADTHRESHOLD) {
                    await this.RebootPC();
                }

                return;
            }

            if (DeviceInterface.IDeviceType.MATIC == this.deviceType &&
                'auto' != (maticStatus.status.mode)) {
                if (!this.deviceInErrorState) {
                    this.deviceInErrorState = true;
                    this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Faulted);
                }

                return;
            }

            if (!this.inNightHatchMode) {

                const hardwareStatus = await this.GetDeviceHardwareStatus();

                if (DeviceInterface.IHatchStateType.UNKNOWN === hardwareStatus.PharmacistHatchState) {
                    if (!this.deviceInErrorState) {
                        this.deviceInErrorState = true;
                        log('error', 'HARDWARE_RDOOR', {Problem: 'Not receiving sensor readings from the pharmacist hatch lock ', Solution: 'Make sure the pharmacist hatch lock sensor is connected'})
                        this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Faulted);
                    }
                    return;
                }

                if (DeviceInterface.IHatchStateType.OPENED === hardwareStatus.PharmacistHatchState) {
                    if (!this.deviceInErrorState) {
                        this.deviceInErrorState = true;
                        log('error', 'HARDWARE_RDOOR', {Problem: 'The pharmacist hatch lock seems to be open', Solution: 'Make sure the pharmacist hatch lock sensor is properly calibrated'})
                        this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Faulted);
                    }
                    return;
                }

                if (DeviceInterface.IHatchStateType.OPENED === hardwareStatus.CustomerHatchState) {
                    if (!this.deviceInErrorState) {
                        this.deviceInErrorState = true;
                        log('error', 'HARDWARE_FDOOR', {Problem: 'The customer hatch lock seems to be open', Solution: 'Make sure the customer hatch lock sensor is properly calibrated, and nothing is blocking the hatch from closing'})
                        this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Faulted);
                    }
                    return;
                }
            }

            // if (!this.meditechDevice.IsMaticDeviceInUse() &&
            //     plcStatus.boxDetect.softwareCounter > 0 &&
            //     !this.inNightHatchMode ) {
            //         log('warn', 'Unauthorised delivery, rejecting...', plcStatus.boxDetect);
            //         await this.meditechDevice.StartReject();
            //         return;
            // }

            if (!deviceStatus.internetConnection) {
                if (!this.deviceInErrorState) {
                    this.deviceInErrorState = true;
                    this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Engaged);
                }
                return;
            }
        }

        if (this.deviceSettings.storageSystem?.useRobotStorageSystem) {
            const storageSystemStatus = await this._storageSystem.StatusInfo(true);
            if('ready' !== (storageSystemStatus?.data as wwks2StatusResponse).state) {
                if (this.hasDelivered) {return;}
                if (!this.storageSystemInErrorState) {
                    this.storageSystemInErrorState = true;
                    if (this.cancellableDelivery != null)
                    {
                        this.shouldRejectOnRecovery = true;
                        this.cancellableDelivery?.cancel({
                            Success: false,
                            Status: 'incomplete',
                            Proof: new Array<DeviceInterface.IPictureResponse>(),
                            Log: new Array<string>()
                        });
                    }
                    
                    if (this.inNightHatchMode || this.meditechDevice.IsMaticDeviceInUse()) {
                        VendingMachineLog.hardwareFault('Storage system not ready for delivery');
                        VendingMachineLog.hardwareStatus(JSON.stringify(deviceStatus));
                        this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Faulted);             
                    } else {
                        this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Engaged);
                    }
    
                    log('warn', 'ROBOT_WWKS2_STATUS', {
                        Problem: 'The stock robot is not ready for delivery', 
                        Solution: 'Make sure the stock robot is up and running, has a stable connection with the vending machine and all connected peripherals are in the ready-state.'
                    })
                }

                await this._storageSystem.closeConnection();
                await this._storageSystem.Connect(false);
                return;
            }
        }

        // if (this.hasThirdPartyStockSource) {
        //     const stockSourceStatus = await this._stockSource.StatusInfo(true);
        //     if('ready' !== (stockSourceStatus?.data as wwks2StatusResponse).state) {
        //         if (this.hasDelivered) {return;}
        //         if (!this.storageSystemInErrorState) {
        //             this.storageSystemInErrorState = true;
        //             if (this.inNightHatchMode || this.meditechDevice.IsMaticDeviceInUse()) {
        //                 VendingMachineLog.hardwareFault('3rd party stock source not ready');
        //                 VendingMachineLog.hardwareStatus(JSON.stringify(deviceStatus));
        //                 this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Faulted);             
        //             } else {
        //                 this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Engaged);
        //             }
    
        //             log('warn', '3RD_PARTY_STOCK_SOURCE_WWKS2_STATUS', {
        //                 Problem: 'The 3rd party stock source is not ready', 
        //                 Solution: 'Make sure the 3rd party stock source is up and running and has a stable connection with the vending machine.'
        //             })
        //         }
    
        //         await this._stockSource.closeConnection();
        //         await this._stockSource.Connect(false);
        //         return;
        //     }
        // }

        if (this.deviceInErrorState || this.storageSystemInErrorState) {
            this.localcomMightBeDeadCounter = 0;
            this.storageSystemInErrorState = false;

            if (!this.inNightHatchMode && this.deviceInErrorState) {
                this.deviceInErrorState = false;
            }

            if (this.shouldRejectOnRecovery) {
                await this.Reject();
                this.shouldRejectOnRecovery = false;
            }

            log('info', 'DEVICE_HARDWARE_READY')
            this.DeviceEventEmitter.emit(DeviceInterface.DeviceEvent.Ready);
            this.StockEventEmitter.emit(DeviceInterface.StockEvent.NeedsRefresh);
        }
    }
}