import * as React from 'react';

import moment from 'moment';

import { Infrastructure } from 'api';

import { Calendar } from './Calendar';
import { PrevNextMonth } from './PrevNextMonth';

import styles from './DatePicker.scss';

interface IProps {
    month?: moment.Moment;
    onMonthChange?: (month: moment.Moment) => void;
    date?: moment.Moment;
    dates?: moment.Moment[];
    min?: moment.Moment;
    max?: moment.Moment;
    availableDates?: moment.Moment[];
    onChange?: (newValue: moment.Moment) => void;
    autoFocusedDay?: React.Ref<any>;
    disabled?: boolean;
    style?: React.CSSProperties;
}

export const DatePicker = React.memo(
    (props: IProps) => {
        const branchTimezone = Infrastructure.Container.maybeGetConstant('branchTimezone') || 'UTC';
        const [visibleMonth, setVisibleMonth] = React.useState(props.month || moment().tz(branchTimezone));
        const actualVisibleMonth = props.onMonthChange ? props.month : visibleMonth;

        return (
            <div
                className={styles.DatePicker}
                style={props.style}
            >
                <PrevNextMonth
                    onChange={offset => {
                        if (props.onMonthChange && props.month) {
                            props.onMonthChange(props.month.clone().add(offset, 'months'));
                        } else {
                            setVisibleMonth(visibleMonth.clone().add(offset, 'months'));
                        }
                    }}
                    disabled={props.disabled}
                />

                <Calendar
                    visibleMonth={actualVisibleMonth}
                    min={props.min}
                    max={props.max}
                    availableDates={props.availableDates}
                    selectedDate={props.date}
                    selectedDates={props.dates}
                    onChange={newValue => {
                        if (props.onChange) {
                            let changedValue = newValue;

                            if (props.date) {
                                changedValue = props.date.clone();
                                changedValue.year(newValue.year());
                                changedValue.dayOfYear(newValue.dayOfYear());
                            }

                            props.onChange(changedValue);
                        }
                    }}
                    autoFocusedDay={props.autoFocusedDay}
                    disabled={props.disabled}
                />
            </div>
        );
    },
    (prevProps: Readonly<IProps>, nextProps: Readonly<IProps>) => {
        return (
            ((prevProps.date && nextProps.date && prevProps.date.isSame(nextProps.date, 'day')) || prevProps.date === nextProps.date) &&
            ((prevProps.dates &&
                nextProps.dates &&
                prevProps.dates.map(d => d.format()).join('-') === nextProps.dates.map(d => d.format()).join('-')) ||
                prevProps.dates === nextProps.dates) &&
            ((prevProps.month && nextProps.month && prevProps.month.isSame(nextProps.month, 'month')) ||
                prevProps.month === nextProps.month) &&
            prevProps.style === nextProps.style
        );
    },
);
