import moment from 'moment';

import { Domain } from 'api';

import { getCountryCodeFromLocale } from '@/getCountryCodeFromLocale';

import { isCustomPageSlideContents, isProductWallSlideContents, Slide } from './types';

export function getSlideId(slide: Slide) {
    if (isProductWallSlideContents(slide)) {
        return slide.productWallId;
    } else if (isCustomPageSlideContents(slide)) {
        return slide.customPageId;
    }

    return slide.mediaItemId;
}

export function getDeviceContentHash(device: Domain.DeviceDetails): string {
    return `${device.contentVersion || 0}`;
}

export function getDeviceCountry(device?: Domain.DeviceDetails): Domain.Country {
    const deviceCountry: Domain.Country =
        !device || device.companyLocales.length === 0 ? 'BE' : getCountryCodeFromLocale(device.companyLocales[0]);

    return deviceCountry;
}

export function getDeviceHasPharmaiesOnDutyNearbyInformation(
    deviceCountry: Domain.Country,
    pharmacyApb: string | null | undefined,
): boolean {
    return (deviceCountry === 'BE' && !!pharmacyApb) || deviceCountry === 'LU' || deviceCountry === 'DE';
}

export function getConfiguredIntegrationsForTypes(
    configuredIntegrations: Domain.ConfiguredIntegration[],
    types: Domain.IntegrationCapability[],
) {
    return configuredIntegrations.filter(integration => {
        if (integration.activeStatus !== 'active') {
            return false;
        }

        for (const type of types) {
            if (!integration.integrationTypes.includes(type)) {
                return false;
            }
        }

        return true;
    });
}

export function getConfiguredIntegrationForTypes(
    configuredIntegrations: Domain.ConfiguredIntegration[],
    types: Domain.IntegrationCapability[],
) {
    const integrations = getConfiguredIntegrationsForTypes(configuredIntegrations, types);
    if (integrations.length > 0) {
        return integrations[0];
    }
}

function getPharmacistOnDutyScheduleForWeekDay(
    pharmacistOnDutySchedule: Domain.PharmacistOnDutySchedule,
    day: Domain.Day,
): Domain.PharmacistOnDutyScheduleInterval[] | undefined {
    if (pharmacistOnDutySchedule && pharmacistOnDutySchedule.weekdays[day] && pharmacistOnDutySchedule.weekdays[day].length > 0) {
        return pharmacistOnDutySchedule.weekdays[day];
    }
}

function getPharmacistOnDutyScheduleForCurrentDate(
    pharmacistOnDutySchedule: Domain.PharmacistOnDutySchedule,
    DATETIME_FORMAT: string,
): Domain.PharmacistOnDutyScheduleInterval | undefined {
    if (pharmacistOnDutySchedule && pharmacistOnDutySchedule.exceptions && pharmacistOnDutySchedule.exceptions.length > 0) {
        const now = moment();
        for (const exception of pharmacistOnDutySchedule.exceptions) {
            if (now.isSameOrAfter(moment(exception.from, DATETIME_FORMAT)) && now.isSameOrBefore(moment(exception.till, DATETIME_FORMAT))) {
                return exception;
            }
        }
    }
}

function parseTimeStringToSecondsSinceMidnight(time: string): number {
    const parts = time.split(':');
    if (!time || parts.length !== 2) {
        return -1;
    }

    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);

    return (hours * 60 + minutes) * 60;
}

export function getPharmacistOnDutyScheduleInformation(
    pharmacistOnDutySchedule: Domain.PharmacistOnDutySchedule | undefined,
    videoPhoneIsAvailable: boolean,
) {
    const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';

    let showVideoCall = false;
    let showPhoneNumber: string | boolean = false;
    let isOnDuty = false;

    if (pharmacistOnDutySchedule) {
        const msSinceMidnight = new Date().getTime() - new Date().setHours(0, 0, 0, 0);
        const secondsSinceMidnight = Math.floor(msSinceMidnight / 1000);

        let currentDay = new Date().getDay() - 1;
        if (currentDay < 0) {
            currentDay = 6;
        }

        const currentDayName = Domain.WEEK_DAYS[currentDay];
        const currentDateSchedule = getPharmacistOnDutyScheduleForCurrentDate(pharmacistOnDutySchedule, DATETIME_FORMAT);

        if (currentDateSchedule) {
            isOnDuty = true;

            if (videoPhoneIsAvailable && currentDateSchedule.allowVideoCalls) {
                showVideoCall = true;
            }

            if (currentDateSchedule.displayOnDutyPhoneNumber) {
                showPhoneNumber = currentDateSchedule.phoneNumber;
            }
        } else {
            const currentDaySchedule = getPharmacistOnDutyScheduleForWeekDay(pharmacistOnDutySchedule, currentDayName);
            if (currentDaySchedule) {
                for (const slice of currentDaySchedule) {
                    const fromInSecondsSinceMidnight = parseTimeStringToSecondsSinceMidnight(slice.from);
                    const tillInSecondsSinceMidnight = parseTimeStringToSecondsSinceMidnight(slice.till);

                    if (fromInSecondsSinceMidnight <= secondsSinceMidnight && tillInSecondsSinceMidnight >= secondsSinceMidnight) {
                        isOnDuty = true;

                        if (videoPhoneIsAvailable && slice.allowVideoCalls) {
                            showVideoCall = true;
                        }

                        if (slice.displayOnDutyPhoneNumber) {
                            showPhoneNumber = slice.phoneNumber;
                        }

                        break;
                    }
                }
            }
        }
    }

    return {
        showVideoCall,
        showPhoneNumber,
        isOnDuty,
    };
}
