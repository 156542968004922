import moment from 'moment';

import { Domain } from 'api';

type IGetText = (text: string) => string;
export interface PharmacyOnDutyNearby {
    pharmacy: {
        id?: number | string;
        name: string;
        phoneNumber?: string | null;
        address: string;
        geoDescription?: string | null;
        getDescription: (gettext: IGetText) => string;
    };
}

const formatGeowacthResponse = (response: Domain.GeowacthPharmacyOnDutyList): PharmacyOnDutyNearby[] => {
    const { PharmaciesOnDuty } = response;
    return PharmaciesOnDuty.map(pharmacyResult => {
        return {
            pharmacy: {
                id: pharmacyResult.PharmacyName,
                name: pharmacyResult.PharmacyName,
                address: pharmacyResult.PharmacyAddress,
                description: pharmacyResult.PharmacyAddressDesc,
                getDescription: () => pharmacyResult.ExtraDutyInfo || '',
                geoDescription: pharmacyResult.PharmacyDistance ? pharmacyResult.PharmacyDistance.toFixed(2) + ' km' : '',
            },
        };
    });
};

const formatGardeResponse = (response: Domain.GardePharmacyOnDutyList): PharmacyOnDutyNearby[] => {
    const { garde } = response;
    const today = moment().format('YYYY-MM-DD');
    const pharmacyItem = garde.find(item => item.date === today);
    if (!pharmacyItem) {
        return [];
    }
    const { pharmacie: currentDayPharmacies } = pharmacyItem.pharmacies;
    return currentDayPharmacies.map(pharmacy => {
        return {
            pharmacy: {
                name: pharmacy.nom,
                address: pharmacy.adresse,
                phoneNumber: pharmacy.telephone,
                getDescription: () => '',
            },
        };
    });
};

const formatDeResponse = (response: Domain.NotdienstdatenPharmacyOnDutyList): PharmacyOnDutyNearby[] => {
    const { apotheken } = response;
    let originalPharmacies = apotheken.apotheke;

    if (!Array.isArray(originalPharmacies)) {
        originalPharmacies = [originalPharmacies];
    }

    return originalPharmacies.map(apotheke => ({
        pharmacy: {
            id: parseInt(apotheke.id),
            name: apotheke.name || '',
            phoneNumber: apotheke.telefon,
            address: `${apotheke.strasse}, ${apotheke.plz} ${apotheke.ort}`,
            geoDescription: apotheke.distanz ? Number(apotheke.distanz).toFixed(2) + ' km' : '',
            getDescription: (gettext: IGetText) =>
                gettext('Operational from :startdatum: at :startzeit: to :enddatum: at :endzeit:')
                    .replace(':startdatum:', apotheke.startdatum || '')
                    .replace(':startzeit:', apotheke.startzeit || '')
                    .replace(':enddatum:', apotheke.enddatum || '')
                    .replace(':endzeit:', apotheke.endzeit || ''),
        },
    }));
};

interface GetPharmaciesOnDutyNearbyParams {
    country: Domain.Country;
    language?: string;
}

export const getPharmaciesOnDutyNearby = async ({
    country,
    GetPharmaciesOnDuty,
    ...rest
}: GetPharmaciesOnDutyNearbyParams & {
    GetPharmaciesOnDuty: (
        params: GetPharmaciesOnDutyNearbyParams,
    ) => Promise<Domain.GeowacthPharmacyOnDutyList | Domain.GardePharmacyOnDutyList | Domain.NotdienstdatenPharmacyOnDutyList | null>;
}) => {
    try {
        const response = await GetPharmaciesOnDuty({ ...rest, country });
        if (country === 'BE') {
            return formatGeowacthResponse(response as Domain.GeowacthPharmacyOnDutyList);
        } else if (country === 'LU') {
            return formatGardeResponse(response as Domain.GardePharmacyOnDutyList);
        } else if (country === 'DE') {
            return formatDeResponse(response as Domain.NotdienstdatenPharmacyOnDutyList);
        }
    } catch (e) {
        console.error(e);
    }
    return [];
};
