import * as React from 'react';

import classNames from 'classnames';
import moment from 'moment';

import color from '@/color';
import { KeyboardHandler } from '@/core';

import styles from './DatePicker.scss';

interface Range {
    from: moment.Moment;
    to: moment.Moment;
}

export const CalendarDay = React.memo(
    (props: {
        date: moment.Moment;
        selectedDate?: moment.Moment;
        selectedDates?: moment.Moment[];
        selectedClassName?: string;
        selection?: Range;
        secondarySelection?: Range;
        onChange?: (selectedDate?: moment.Moment) => void;
        min?: moment.Moment;
        max?: moment.Moment;
        availableDates?: moment.Moment[];
        autoFocusRef?: React.Ref<any>;
        secondarySelectedClassName?: string;
        overlappingSelectedClassName?: string;
        disabled?: boolean;
    }) => {
        const today = moment();
        const keyHander = new KeyboardHandler();

        const isSelected =
            (props.selectedDate && props.date.isSame(props.selectedDate, 'day')) ||
            (props.selectedDates && props.selectedDates.find(selectedDate => props.date.isSame(selectedDate, 'day'))) ||
            (props.selection &&
                props.date.isSameOrAfter(props.selection.from, 'day') &&
                props.date.isSameOrBefore(props.selection.to, 'day'));
        const prevIsSelected =
            isSelected &&
            props.selection &&
            props.date.clone().subtract(1, 'day').isSameOrAfter(props.selection.from, 'day') &&
            props.date.clone().subtract(1, 'day').isSameOrBefore(props.selection.to, 'day');
        const nextIsSelected =
            isSelected &&
            props.selection &&
            props.date.clone().add(1, 'day').isSameOrAfter(props.selection.from, 'day') &&
            props.date.clone().add(1, 'day').isSameOrBefore(props.selection.to, 'day');

        const isSelectedSecondary =
            props.secondarySelection &&
            props.date.isSameOrAfter(props.secondarySelection.from, 'day') &&
            props.date.isSameOrBefore(props.secondarySelection.to, 'day');
        const prevIsSelectedSecondary =
            isSelectedSecondary &&
            props.secondarySelection &&
            props.date.clone().subtract(1, 'day').isSameOrAfter(props.secondarySelection.from, 'day') &&
            props.date.clone().subtract(1, 'day').isSameOrBefore(props.secondarySelection.to, 'day');
        const nextIsSelectedSecondary =
            isSelectedSecondary &&
            props.secondarySelection &&
            props.date.clone().add(1, 'day').isSameOrAfter(props.secondarySelection.from, 'day') &&
            props.date.clone().add(1, 'day').isSameOrBefore(props.secondarySelection.to, 'day');

        const primarySelectedColor = props.selectedClassName;
        const secondarySelectedColor = props.secondarySelectedClassName || color.BG.Primary.Blue;
        const overlappingSelectedColor = props.overlappingSelectedClassName || color.BG.Primary.Green;

        const selectedColor =
            isSelected && isSelectedSecondary
                ? overlappingSelectedColor
                : isSelected
                  ? primarySelectedColor
                  : isSelectedSecondary
                    ? secondarySelectedColor
                    : '';

        return (
            <td
                tabIndex={0}
                ref={props.autoFocusRef}
                className={classNames(
                    styles.DatePickerCalendarDay,
                    props.date.isSame(today, 'day') ? styles.DatePickerCalendarCurrentDay : undefined,
                    !dayIsSelectable(props.date, props.min, props.max, props.availableDates)
                        ? styles.DatePickerCalendarOutsideDay
                        : undefined,
                    isSelected || isSelectedSecondary ? styles.DatePickerCalendarSelectedDay : undefined,
                    prevIsSelected || prevIsSelectedSecondary ? styles.DatePickerCalendarSelectedDayPrevToo : undefined,
                    nextIsSelected || nextIsSelectedSecondary ? styles.DatePickerCalendarSelectedDayNextToo : undefined,
                )}
                onClick={event => {
                    event.preventDefault();

                    if (props.onChange && !props.disabled) {
                        props.onChange(props.date);
                    }
                }}
                onKeyDown={keyHander.handleKey(['ENTER', 'SPACE'], () => {
                    if (props.onChange && !props.disabled) {
                        props.onChange(props.date);
                    }
                })}
                data-test-id={`day-${props.date.isSame(today, 'day') ? 'current' : props.date.format('D')}`}
            >
                <span className={selectedColor}>{props.date.format('D')}</span>
            </td>
        );
    },
);

function dayIsSelectable(day: moment.Moment, min?: moment.Moment, max?: moment.Moment, availableDates?: moment.Moment[]): boolean {
    if (min && !day.isSameOrAfter(min, 'day')) {
        return false;
    }

    if (max && !day.isSameOrBefore(max, 'day')) {
        return false;
    }
    if (availableDates && !availableDates.find(date => date.isSame(day, 'day'))) {
        return false;
    }

    return true;
}
